export default {
  name: 'Gestauto',
  components: {
    SecaoAcionar: () => import('src/components/SecaoAcionar/SecaoAcionar.vue')
  },
  meta () {
    return {
      title: 'Gestauto - NetCarros',
      description: {
        name: 'description',
        content:
              'Quer saber mais sobre como funciona o programa de assinatura? Acesse agora e tira suas dúvidas!'
      },
      property: {
        name: 'og:image',
        content: 'https://www.netcarros.com.br/seo/netcarros-duvidas.png'
      },
      link: {
        material: {
          rel: 'canonical',
          href: 'https://www.netcarros.com.br' + this.$route.fullPath
        }
      },
      meta: [
        {
          property: 'og:title',
          content: 'Gestauto - NetCarros'
        },
        {
          name: 'description',
          content:
            'Quer saber mais sobre como funciona o programa de assinatura? Acesse agora e tira suas dúvidas!'
        },
        { property: 'revisit-after', content: '5 days' },
        {
          property: 'og:image',
          content: 'https://www.netcarros.com.br/seo/netcarros-duvidas.png'
        },
        {
          property: 'og:image:secure_url',
          content: 'https://www.netcarros.com.br/seo/netcarros-duvidas.png'
        },
        {
          property: 'og:url',
          content: 'https://www.netcarros.com.br' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'NetCarros' },
        { property: 'fb:admins', content: '', vmid: 'fb:admins' },
        { property: 'og:locale', content: 'pt_BR' }
      ]
    }
  },
  data () {
    return {
      showPlanDialog: false,
      currentPlan: 0,
      plans: [
        {
          name: 'Plano Motor e Câmbio',
          title: 'Conhecendo o Plano Motor e Câmbio',
          subtitle: '30 itens certificados',
          items: ['Motor (Gasolina, Flex, Etanol, Diesel e GNV)', 'Câmbio CVT', 'Câmbios robotizados (Dualogic, I-Motion, Easytronic)', 'Câmbios automatizados (Powershift, DSG)', 'Câmbio manal (componentes)', 'Câmbio automático (componentes)', 'Sistema de arrefecimento']
        },
        {
          name: 'Plano Prime',
          title: 'Conhecendo o Plano Prime',
          subtitle: '100 itens certificados',
          items: ['Sistema de alimentação', 'Sistema de admissão', 'Sistema elétrico', 'Sistema de freios', 'Diferencial', 'Sistema de transmissão', 'Sistema de exaustão']
        },
        {
          name: 'Plano Excellence',
          title: 'Conhecendo o Plano Excellence',
          subtitle: '150 itens certificados',
          items: ['Sistema de direção', 'High Tech', 'Mão de obra', 'Sistema de Freios', 'Diferencial', 'Sistema de transmissão', 'Sistema de exaustão']
        }

      ]
    }
  },
  methods: {
    openPlanDialog (plan) {
      this.currentPlan = plan
      this.showPlanDialog = true
    }
  },
  watch: {
  },
  created () {
  }
}
